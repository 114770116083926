import revive_payload_client_4sVQNw7RlN from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/zipup-webapp/zipup-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_apollo_client_N8WWAksyFd from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/01.apollo-client.ts";
import _02_native_HdgdaEPHcb from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/02.native.ts";
import _03_auth_0qOenMw9ot from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/03.auth.ts";
import amplitude_qcoZpE5aTU from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/amplitude.ts";
import channel_talk_client_8M2s6eRS0h from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/channel-talk.client.ts";
import dialog_uimL6oljqr from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/dialog.ts";
import gotcha_eDhdY5c4v8 from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/gotcha.ts";
import handle_link_aiBXUyhNVa from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/handle-link.ts";
import loading_GxQ2Z9Goxa from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/loading.ts";
import maska_UHaKf2z1iQ from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/maska.ts";
import nl2br_7CTUa1tucm from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/nl2br.ts";
import safety_report_router_UHLgZosN86 from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/safety-report-router.ts";
import share_GJAmefmsFm from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/share.ts";
import typed_store_c61yqtgR2V from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/typed-store.ts";
import v_select_pBubpC5a9f from "/home/runner/work/zipup-webapp/zipup-webapp/plugins/v-select.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes,
  _01_apollo_client_N8WWAksyFd,
  _02_native_HdgdaEPHcb,
  _03_auth_0qOenMw9ot,
  amplitude_qcoZpE5aTU,
  channel_talk_client_8M2s6eRS0h,
  dialog_uimL6oljqr,
  gotcha_eDhdY5c4v8,
  handle_link_aiBXUyhNVa,
  loading_GxQ2Z9Goxa,
  maska_UHaKf2z1iQ,
  nl2br_7CTUa1tucm,
  safety_report_router_UHLgZosN86,
  share_GJAmefmsFm,
  typed_store_c61yqtgR2V,
  v_select_pBubpC5a9f
]