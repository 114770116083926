const CHANNEL_TALK_PATHS = [
  '/app/benefits/monthly-pay',
  // 필요한 다른 경로들 추가
]

export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    const { $initChannelTalk, $bootChannelTalk, $shutdownChannelTalk } =
      useNuxtApp()

    if (CHANNEL_TALK_PATHS.some((path) => to.fullPath.includes(path))) {
      $initChannelTalk()
      $bootChannelTalk(true)
    } else {
      $shutdownChannelTalk()
    }
  }
})
