import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin(() => {
  const authStore = useAuthStore()

  const initChannelTalk = async () => {
    return new Promise((resolve) => {
      ;(function () {
        var w = window
        if (w.ChannelIO) {
          window.ChannelIO?.('shutdown')
          resolve(true)
          return w.console.error('ChannelIO script included twice.')
        }
        var ch = function () {
          ch.c(arguments)
        }
        ch.q = []
        ch.c = function (args) {
          ch.q.push(args)
        }
        w.ChannelIO = ch
        function l() {
          if (w.ChannelIOInitialized) {
            return
          }
          w.ChannelIOInitialized = true
          var s = document.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
          s.onload = function () {
            resolve(true)
          }
          var x = document.getElementsByTagName('script')[0]
          if (x.parentNode) {
            x.parentNode.insertBefore(s, x)
          }
        }
        if (document.readyState === 'complete') {
          l()
        } else {
          w.addEventListener('DOMContentLoaded', l)
          w.addEventListener('load', l)
        }
      })()
    })
  }

  const bootChannelTalk = (hideDefault = false, selector = '#channeltalk') => {
    window.ChannelIO?.('boot', {
      pluginKey: 'adb4462c-fadb-4bb7-97ff-5b9d8ba84879',
      customLauncherSelector: selector,
      hideChannelButtonOnBoot: hideDefault,
      memberId: authStore.user?.id,
      profile: {
        phone: authStore.user?.phone,
        birthday: authStore.user?.birthday,
        age: authStore.user?.age,
        email: authStore.user?.email,
      },
    })
  }

  const shutdownChannelTalk = () => {
    window.ChannelIO?.('shutdown')
  }

  return {
    provide: {
      initChannelTalk,
      bootChannelTalk,
      shutdownChannelTalk,
    },
  }
})
