import validate from "/home/runner/work/zipup-webapp/zipup-webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import channel_45talk_45global from "/home/runner/work/zipup-webapp/zipup-webapp/middleware/channel-talk.global.ts";
export const globalMiddleware = [
  validate,
  channel_45talk_45global
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/zipup-webapp/zipup-webapp/middleware/auth.ts"),
  "monthly-pay": () => import("/home/runner/work/zipup-webapp/zipup-webapp/middleware/monthly-pay.ts"),
  payment: () => import("/home/runner/work/zipup-webapp/zipup-webapp/middleware/payment.ts"),
  register: () => import("/home/runner/work/zipup-webapp/zipup-webapp/middleware/register.ts"),
  "slide-transition": () => import("/home/runner/work/zipup-webapp/zipup-webapp/middleware/slide-transition.ts")
}