export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"https://developers.kakao.com/sdk/js/kakao.js"},{"src":"//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"},{"src":"https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"},{"src":"https://democpay.payple.kr/js/v1/payment.js"},{"src":"https://cpay.payple.kr/js/v1/payment.js"},{"hid":"fb-pixel","innerHTML":"\n            !function(f,b,e,v,n,t,s)\n            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n            n.queue=[];t=b.createElement(e);t.async=!0;\n            t.src=v;s=b.getElementsByTagName(e)[0];\n            s.parentNode.insertBefore(t,s)}(window, document,'script',\n            'https://connect.facebook.net/en_US/fbevents.js');\n            fbq('init', '203170379193855');\n            fbq('track', 'PageView');\n          ","type":"text/javascript"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover","title":"zipup"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'